const elements = {
  decreaseBtn: document.getElementById('decrease'),
  increaseBtn: document.getElementById('increase'),
  amountInput: document.getElementById('amount-input'),
  shoppingCartBtn: document.getElementById('shopping-cart-btn'),
  amountOptions: document.querySelectorAll('.gift-card-opt')
}

const handlers = {
  decrease: () => {
    elements.decreaseBtn.addEventListener('click', () => {
      if (elements.amountInput.value > 1) {
        elements.amountInput.value = --elements.amountInput.value;
      }

      if (elements.decreaseBtn.classList.contains('can-decrease') && elements.amountInput.value == 1) {
        elements.decreaseBtn.classList.remove('can-decrease');
      }

    })
  },
  increase: () => {
    elements.increaseBtn.addEventListener('click', () => {

        elements.amountInput.value = ++elements.amountInput.value

        if (!elements.decreaseBtn.classList.contains('can-decrease') && elements.amountInput.value > 1) {
          elements.decreaseBtn.classList.add('can-decrease');
        }
  })
  },
  shopGiftCart: () => {
    elements.shoppingCartBtn.addEventListener('click', () => {
      const endpoint = elements.shoppingCartBtn.getAttribute('data-url');
      const checkedParent  = Array.from(elements.amountOptions).find(option => option.querySelector('input').checked);
      const checkedInputId = checkedParent.querySelector('input').id;

      const params = `shopping_cart=[{"product_id":${checkedInputId}, "quantity": ${elements.amountInput.value}}]`;
      const shoppingApiUrl = `${endpoint}?${params}`;
      
      window.open(shoppingApiUrl, '_blank');
    })
  },
  onInputChange: () => {
    elements.amountInput.addEventListener('change', () => {

        if (elements.amountInput.value > 1) {
          elements.decreaseBtn.classList.add('can-decrease');
        }

        if (elements.amountInput.value < 2) {
          elements.decreaseBtn.classList.remove('can-decrease');
        }

    })
  }
}

function init () {
  if (elements.decreaseBtn && elements.amountInput) {
    handlers.decrease()
  }

  if(elements.increaseBtn && elements.decreaseBtn && elements.amountInput) {
    handlers.increase()
  }

  if(elements.shoppingCartBtn && elements.amountOptions && elements.amountInput) {
    handlers.shopGiftCart()
  }

  if(elements.amountInput && elements.decreaseBtn) {
    handlers.onInputChange()
  }
}

init()