// Our main css
import '../css/app.css'

import '@lottiefiles/lottie-player'

//Our main js
import './filterMovies.js'
import './filterShowTimes.js'
import './giftCard.js'

/**
 * 🦄: Now do your magic.
 */

import Alpine from 'alpinejs'

window.Alpine = Alpine
Alpine.start()

/**
 * 🌈: Swiper
 */
import Swiper from 'swiper'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

var swiper = new Swiper('.swiper-container', {
  modules: [Navigation, Autoplay],
  slidesPerView: 1,
  autoplay: {
    delay: 2000,
  },
  grabCursor: true,
  speed: 2000,
  loop: false,

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
})

var moviesSwiper = new Swiper('.movies-slider', {
  modules: [Navigation, Autoplay],
  slidesPerView: 1,
  autoplay: {
    delay: 2000,
  },
  grabCursor: true,
  speed: 2000,
  navigation: {
    nextEl: '.movie-button-next',
    prevEl: '.movie-button-prev',
  },
  breakpoints: {
    640: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 2.1,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 2.1,
      spaceBetween: 10,
    },
  },
})

const menuButton = document.querySelector('.menu-button')
const body = document.body

menuButton.addEventListener('click', function () {
  if (body.classList.contains('menu-open')) {
    body.classList.remove('menu-open')
  } else {
    body.classList.add('menu-open')
  }
})
